@mixin commonTitle() {
	font-family: Futura;
	font-size: 35px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.83;
	letter-spacing: normal;
	color: #02435d;
}

@mixin commonContent($textAlign) {
	font-family: Cabin;
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: normal;
	color: #02435d;
}

.about-main-block {
	.about-content-block {
		.section-splitter-1 {
			padding-top: 120px;
			width: max-content;
  			margin: auto;
		}
		.about-author-container {
			display: flex;
			flex-direction: column;
			.about-title {
				margin-top: 7px;
				font-family: Futura;
				font-size: 35px;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				line-height: 64px;
				letter-spacing: normal;
				color: #02435d;
			}
			.about-inner-content-block {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				padding-top: 29px;
				span {
					padding-bottom: 10px;
					width: 700px;
					@include commonContent(center);
					text-align: center;
				}
			}
		}
		.section-splitter-2 {
			//padding-top: 120px;
			width: max-content;
  			margin: auto;
		}
	}
}

@media only screen and (max-width: 768px) {
	.about-main-block {
		.about-content-block {
			.section-splitter-1 {
				// padding-top: 48px !important;
				width: max-content;
  				margin: auto;
			}
			.about-author-container {
				.about-title{
					font-size: 30px !important;
				}
				.about-inner-content-block{
					.about-content-sub-text1,.about-content-sub-text2{
						font-size: 15px !important;
						width: 79% !important;
					}
				}
			}
			.about-work-container {
				.about-work-main-block {
					// .about-work-item {
					// 	margin: 0 !important;
					// }
				}
			}
		}
	}
}
