.team-main-block {
	display: flex;
	flex-direction: column;
	.team-section-splitter {
		padding-top: 95px;
		width: max-content;
  		margin: auto;
	}
	.team-title-block {
		padding: 0px 0 73px 0;
		font-family: Futura;
		font-size: 35px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 64px;
		letter-spacing: normal;
		text-align: center;
		color: #02435d;
	}
	.founter-overview-container {
		display: flex;
		width: 100%;
		margin: 60px 0 40px 0;
		justify-content: center;
		.each-founter {
			display: flex;
			align-self: center;
			flex-direction: column;
			align-items: center;
			width: 418px;
			padding-top: 200px;
			border-radius: 10px;
			box-shadow: 0 5px 9px 5px rgba(204, 204, 204, 0.5);
			background-color: #ffffff;
			position: relative;
			.founder-icon-wrapper {
				top: -70px;
				position: absolute;
				border-radius: 50%;
				border: 10px solid #FCFAFA;
				.founter-icon {
					width: 240px;
					height: 240px;
				}
				.founter-lkndn-icon {
					position: absolute;
					top: 78%;
					left: 70%;
					cursor: pointer;
				}
			}
			.founter-name {
				margin-top: 20px;
				padding-left: 10px;

				font-family: Futura;
				font-size: 20px;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				//line-height: 3.2;
				letter-spacing: normal;
				text-align: center;
				color: #02435d;
			}
			.founter-read-more{
				display: none;
			}
			.founter-designation {
				padding-top: 0px !important;
				opacity: 1;
				font-family: Futura;
				font-weight: 500;
			}
			.founter-description {
				font-family: Cabin;
				font-weight: 400;
				padding-bottom: 15px;
			}
			.founter-designation,
			.founter-description {
				overflow-y: auto;
				padding-left: 40px;
				padding-right: 34px;
				text-align: left;
				padding-top: 16px;
				font-size: 15px;
				font-style: normal;
				font-stretch: normal;
				line-height: 28px;
				letter-spacing: normal;
				text-align: center;
				color: #02435d;
			}
		}
	}
	.know-more-button-block {
		display: flex;
		justify-content: center;
		margin-bottom: 112px;
		.know-more-button {
			width: 200px;
			height: 60px;
			border-radius: 30px;

			font-family: Cabin;
			font-size: 15px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 60px;
			letter-spacing: normal;
			text-align: center;
			color: #ffffff;
		}
	}
}

@media only screen and (max-width: 768px) {
	.team-title-block{
		padding-bottom: 35px !important;
	}
	.founter-overview-container{
		flex-direction: column !important;
		.each-founter {
			width: 90% !important;
			margin: 70px 20px 20px 20px !important;
			padding-top: 150px !important;

			&:first-child {
				margin-top: 0 !important;
			}

			.founder-icon-wrapper {
				top: -45px !important;
			}
			.founter-icon {
				width: 170px !important;
				height: 170px !important;
			}
			.founter-lkndn-icon {
				top: 70% !important;
			}
			.founter-read-more{
				display: block !important;
				text-decoration: underline;
				font-size: 16px;
				font-weight: 500;
				cursor: pointer;
				padding-bottom: 15px;
			}
			.founter-designation {
				opacity: 0.4 !important;
			}
			.founter-description {
				//display: none !important;
			}
			.show{
				display: block !important;
			}
		}
	}
	.know-more-button-block {
		margin-bottom: 65px !important;
	}
	
}
