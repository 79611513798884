.res-employ-opinion-container{
    .res-employ-opinion-wrapper{
        display: flex;
        overflow-x: hidden;
        .opinion-inner-wrapper{
            margin: 20px 0% 20px 0% !important;
            min-width: 100% !important;
            .opinion-inner-block{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);
                border: solid 1px rgba(2, 67, 93, 0.18);
                width: 90% ;
                margin: 0 5% ;
                border-radius: 10px;
                .opinion-header-block{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-top: 20px;
                    .person-icon{
                        height: 120px;
                        width: 130px;
                        padding-left: 20px;
                    }
                    .person-content-wrapper{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding-left: 20px;
                        .person-name{
                            font-family: Futura;
                            font-size: 20px;
                            font-weight: 500;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 25px;
                            letter-spacing: normal;
                            color: #02435d;
                        }
                        .person-designation{
                            opacity: 0.6;
                            font-family: Futura;
                            font-size: 15px;
                            font-weight: 500;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 40px;
                            letter-spacing: normal;
                            color: #02435d;
                        }
                    }
                }
                .opinion-description{
                    padding: 0 15px;
                    text-align: left;
                    font-family: Cabin;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.79;
                    letter-spacing: normal;
                    color: #02435d;
                    height: 100%;
                    overflow: scroll;
                }
            }
        }
    }
}