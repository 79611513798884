.investors-main-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: #f1fbff;
	.investor-section-splitter {
		width: max-content;
		margin: auto;
		margin-top: 80px;
	}
	.investors-title {
		font-family: Futura;
		font-size: 35px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 64px;
		letter-spacing: normal;
		text-align: center;
		color: #02435d;
	}
	.investors-details {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 40px 0 65px 0;
		width: 90%;
	}
}

@media only screen and (max-width: 768px) {
	.investors-main-block {
		.investor-section-splitter{
			width: max-content;
  			margin: auto;
			margin-top: 60px !important;
		}
		.investors-title {
			font-size: 32px !important;
		}
		.investors-details {
			// display: flex;
			// flex-direction: column;
			flex-wrap: wrap;
			width: 100%;
			margin: 0px !important;
			//flex-wrap: nowrap !important; 
			.investor-logo {
				// width: 25% !important;
				margin-bottom: 70px;
				margin-top: 40px;
				margin-left: 15px;
				margin-right: 25px;
			}
			.ef-logo{
				
			}
		}
	}
}
