$url_d_bg: url(../../assets/images/footer-bg.svg);
$url_m_bg: url(../../assets/images/footer-bg-mobile.png);

.footer-main-block {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	//border: solid 1px #979797;
	//background-color: #d8d8d8;
	.f-main-contents-container {
		display: flex;
		justify-content: space-between;
		//width: 100%;
		margin: 25px;
		padding-bottom: 10px;
		.f-social-info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding-left: 25px;
			// .f-social-main-icon {
			// 	width: 184px;
			// 	height: 69px;
			// 	background-color: #d8d8d8;
			// 	margin-bottom: 29px;
			// }
			.f-social-media-icon-block {
				display: flex;
				.f-fb-icon,
				.f-tr-icon,
				.f-ytb-icon {
					//width: 19.9px;
					height: 35px;
					padding-right: 20px;
				}
				.f-fb-icon {
				}
				.f-tr-icon {
					font-family: Futura;
					font-size: 25px;
					font-weight: 500;
					//font-style: normal;
					//font-stretch: normal;
					//line-height: 1.2;
					letter-spacing: normal;
					color: #37b8dc;
				}
				.f-ytb-icon {
					cursor: pointer;
				}
			}
			.f-copy-right-block {
				padding-top: 15px;
				font-family: Cabin;
				font-size: 16px;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.63;
				letter-spacing: normal;
				color: #02435d;
			}
		}
		.f-main-sections-info {
			display: flex;
			padding-right: 45px;
			.f-inner-section-1,
			.f-inner-section-2 {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-right: 15px;
				span {
					font-family: Cabin;
					font-size: 15px;
					font-weight: 400;
					font-style: normal;
					font-stretch: normal;
					line-height: 28px;
					letter-spacing: normal;
					color: #02435d;
					cursor: pointer;
				}
			}
		}
	}
}

@media only screen and (min-width: 1025px) {
	.footer-main-block {
		height: 350px !important;
	}
}

@media (max-width: 1023px) and (min-width: 768px) {
	.footer-main-block {
		background-size: 100% 120% !important;
		background-image: $url_d_bg !important;
		.f-main-sections-info {
			padding: 16px 0 14px 20px;
		}
	}
}

@media only screen and (max-width: 767px) {
	.footer-main-block {
		height: 300px !important;
		background-size: 100% 160% !important;
		background-image: $url_m_bg !important;
		.f-main-contents-container {
			flex-direction: column-reverse !important;
			margin: 0px !important;
			//padding: 0 20px 0 0px !important;
			.f-main-sections-info {
				padding-bottom: 16px;
				padding-left: 20px !important;
			}
			.f-social-info {
				padding: 25px 0 14px 20px;
			}
		}
	}
}

.tap-top {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 99;
  color: #fff;
  text-align: center;
  background: linear-gradient(to top, #1ca7e5, #65d7c8);
  border-radius: 100%;
  cursor: pointer;
  visibility: hidden;
	border: 1px solid #fff;
	.tap-top-icon {
		width: 50%;
		margin-top: 10px;
	}
}

.fadeOut {
	opacity: 0;
	transition: visibility 1s, opacity 1s linear;
	visibility: hidden;
}
.fadeIn {
	opacity: 1;
	transition: visibility 1s, opacity 1s linear;
	display: block;
	visibility: visible;
}
