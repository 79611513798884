.life-main-block {
	display: flex;
	flex-direction: column;
	.life-section-splitter {
		padding-top: 120px;
		width: max-content;
  		margin: auto;
	}
	.life-title-block {
		//padding-top: 96px;
		padding-bottom: 25px;
		font-family: Futura;
		font-size: 35px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 64px;
		letter-spacing: normal;
		text-align: center;
		color: #02435d;
	}
	.life-subtitle-block {
		font-family: Cabin;
		font-size: 24px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 2.67;
		letter-spacing: normal;
		text-align: center;
		color: #02435d;
	}
	.life-description-block {
		display: flex;
		flex-direction: column;
		padding-top: 20px;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		padding-bottom: 58px;
		.life-description-context {
			width: 55%;
			margin-bottom: 15px;
			font-family: Cabin;
			font-size: 15px;
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
			line-height: 28px;
			letter-spacing: normal;
			text-align: center;
			color: #00374c;
		}
	}
	.life-grid-wrapper {
		display: flex;
		//width: 100%;
		flex-wrap: wrap;
		padding-left: 11%;
		// margin: 0px 100px;
		//padding: 0 10% 120px 10%;
		.life-grid-item {
			width: 21%;
			height: 226px;
			//opacity: 0.3;
			padding: 4px;
			//background-color: #d8d8d8;
		}
	}
	.join-us-block {
		display: flex;
		flex-direction: column;
		.join-us-section-splitter {
			padding-top: 120px;
			width: max-content;
  			margin: auto;
		}
		.join-us-title {
			font-family: Futura;
			font-size: 35px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 64px;
			letter-spacing: normal;
			text-align: center;
			color: #02435d;
		}
		.join-us-advantage-container {
			display: flex;
			//justify-content: space-around;
			align-self: center;
			margin: 60px 0;
			//width: 100%;
			.each-advantage-wrapper{
				.each-advantage-block {
					display: flex;
					flex-direction: column;
					align-items: center;
					//width: 20%;
					.advantage-icon {
						//border-radius: 50%;
						//width: 50%;
						width: 86px;
						height: 86px;
					}
					.advantage-item-wrapper{
						display: flex;
						flex-direction: column;
						align-items: center;
						.advantage-title {
							font-family: Futura;
							font-size: 20px;
							font-weight: 500;
							font-style: normal;
							font-stretch: normal;
							line-height: 64px;
							letter-spacing: normal;
							text-align: center;
							color: #02435d;
						}
						.advantage-description {
							width: 80%;
							font-family: Cabin;
							font-size: 15px;
							font-weight: 400;
							font-style: normal;
							font-stretch: normal;
							line-height: 28px;
							letter-spacing: normal;
							text-align: center;
							color: #00374c;
						}
					}
					
				}
			}
			
		}
	}
}

@media only screen and (max-width: 768px) {
	.life-section-splitter{
		padding-top: 60px !important;
		width: max-content;
  		margin: auto;
	}
	.life-title-block {
		font-size: 31px !important;
		padding-bottom: 0px !important;
	}
	.life-description-context{
		width: 83% !important;
	}
	.read-more{
		font-weight: 500;
		font-size: 16px;
		text-decoration: underline;
		color: #02435d;
	}
	.life-grid-wrapper {
		flex-wrap: nowrap !important;
    	overflow-x: hidden !important;
		padding: 0 !important;
		padding-bottom: 0px !important;
		.life-grid-item {
			width: 95% !important;
			height: 100% !important;
			padding: 10px !important;
		}
	}
	.join-us-block {
		.join-us-section-splitter{
			padding-top: 60px !important;
			width: max-content;
  			margin: auto;
		}
		.join-us-advantage-container {
			margin: 0 !important;
			flex-wrap: nowrap;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
			padding: 0px 2px !important;
			.each-advantage-wrapper{
				margin: 20px 0% 20px 0% !important;
				min-width: 100% !important;
				.each-advantage-block {
					//width: 50% !important;
					padding-bottom: 12px;
					flex: 0 0 auto;
					flex-direction: row !important;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
					//margin: 20px 10px 20px 10px !important;
					//border-radius: 5px;
					width: 95%;
					margin: 0 2%;
					max-width: 100%;
					.advantage-icon{
						padding-left: 10px !important;	
					}
					.advantage-item-wrapper{
						display: flex;
						flex-direction: column;
						align-items: flex-start !important;	
						padding-left: 20px;		
						.advantage-title{
							text-align: left !important;	
						}		
						.advantage-description {
							padding-bottom: 10px;
							width: 90% !important;
							text-align: left !important;
							//display: none !important;
						}
					}
					
				}
			}
		}
		.employ-opinion-wrapper {
			//display: none !important;
		}
	}
	.control-circle-dots{
		display: flex;
		justify-content: center;
		padding-left: 0px;
		.circle-dot{
			transition: opacity .25s ease-in;
			opacity: .3;
			box-shadow: 1px 1px 2px rgba(0,0,0,.9);
			background: #fff;
			border-radius: 50%;
			width: 8px;
			height: 8px;
			cursor: pointer;
			display: inline-block;
			margin: 0 8px;
		}
		.seleted{
			opacity: 1 !important;
		}
	}
}

@media (max-width: 768px) {
  .join-us-advantage-container {
    max-width: 95vw;
  }
}

@media (min-width: 769px) {
  .join-us-advantage-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .join-us-advantage-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .join-us-advantage-container {
    max-width: 1020px;
  }
}