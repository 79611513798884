$Futura-font-name: Futura;
// $Roboto_Url_Path: 'https://assets.embibe.com/staging/fonts/Roboto/';
$Futura_Url_Path: './assets/fonts/Futura/';

$Cabin-font-name: Cabin;
$Cabin_Url_path: './assets/fonts/Cabin/';

/* Futura */

@font-face {
	font-family: $Futura-font-name;
	src: url($Futura_Url_Path+'Futura_Light_font.ttf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: $Futura-font-name;
	src: url($Futura_Url_Path+'Futura_Light_font.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: $Futura-font-name;
	src: url($Futura_Url_Path+'futura_medium_bt.ttf') format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: $Futura-font-name;
	src: url($Futura_Url_Path+'Futura_Heavy_font.ttf') format('truetype');
	font-weight: 700;
}

/**/

/** Cabin */

@font-face {
	font-family: $Cabin-font-name;
	src: url($Cabin_Url_path+'Cabin-SemiBold.ttf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family:  $Cabin-font-name;
	src: url($Cabin_Url_path+'Cabin-Regular.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: $Cabin-font-name;
	src: url($Cabin_Url_path+'Cabin-Medium.ttf') format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: $Cabin-font-name;
	src: url($Cabin_Url_path+'Cabin-Bold.ttf') format('truetype');
	font-weight: 700;
}

/**/

// @font-face {
// 	font-family: $Roboto-font-name;
// 	src: url($Roboto_Url_Path+'Roboto-Regular.ttf') format('truetype');
// 	font-weight: 400;
// }
// @font-face {
// 	font-family: $Roboto-font-name;
// 	src: url($Roboto_Url_Path+'Roboto-Medium.ttf') format('truetype');
// 	font-weight: 500;
// }
// @font-face {
// 	font-family: $Roboto-font-name;
// 	src: url($Roboto_Url_Path+'Roboto-Bold.ttf') format('truetype');
// 	font-weight: 700;
// }
