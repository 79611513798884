.careers-main-block {
	display: flex;
	flex-direction: column;
	background-color: #f9f9f9;
	.careers-section-splitter {
		padding-top: 88px;
		width: max-content;
  		margin: auto;
	}
	.careers-title-block {
		font-family: Futura;
		font-size: 35px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 64px;
		letter-spacing: normal;
		text-align: center;
		color: #02435d;
	}
	.careers-wrapper-block {
		display: flex;
		padding: 70px 200px 40px 200px;
		// margin: 0 25px 0 25px;
		.job-card {
			display: flex;
			// height: 100%;
			flex-direction: column;
			align-items: flex-start;
			border-radius: 10px;
			background-color: #ffffff;
			//width: 33%;
			min-height: 400px;
			margin: 0 20px;
			overflow: auto;
			padding-bottom: 29.6px;
			.responsive-job-selection{
				display: none;
			}
			.job-icon {
				height: 126.4px;
				width: 100%;
			}
			.job-content-wrapper {
				padding: 0 12px;
				height: 100%;
				margin-top: 10px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.job-title {
					font-family: Futura;
					font-size: 20px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 23px;
					letter-spacing: normal;
					color: #02435d;
					text-align: left
					//margin-top: 39px;
					//margin-bottom: 16px;
				}
				.job-type {
					display: flex;
					padding: 15px 0px 24px 0px;
					.each-job-type {
						pointer-events: none !important;
						margin-right: 5px;
						width: 72px;
						height: 23px;
						border-radius: 11.5px;
						background-color: rgba(2, 67, 93, 0.13);
						font-family: Cabin;
						font-size: 12px;
						font-weight: 400;
						font-style: normal;
						font-stretch: normal;
						line-height: 23px;
						letter-spacing: normal;
						color: #02435d;
					}
				}
				.job-dec-title {
					text-align: left;
					font-family: Cabin;
					font-size: 18px;
					font-weight: 600;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.44;
					letter-spacing: normal;
					color: #02435d;
				}
				.job-description {
					height: 100%;
					overflow-y: auto;
					.job-desc-item {
						display: flex;
						overflow-y: auto;
						.each-desc-pointer {
							//width: 20px;
							width: 5px;
							height: 5px;
							padding-right: 10px;
							padding-top: 11px;
						}
						.each-job-desc {
							text-align: left;
							font-family: Cabin;
							font-size: 15px;
							font-weight: 400;
							font-style: normal;
							font-stretch: normal;
							line-height: 28px;
							letter-spacing: normal;
							color: #00374c;
						}
					}
				}
			}
			.job-more-button {
				padding-left: 24px;
			}
		}
	}
	.apply-job-block {
		padding: 0px 310px 70px 310px;
		font-family: Cabin;
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
		line-height: 28px;
		letter-spacing: normal;
		text-align: center;
		color: #00374c;
		padding-bottom: 89px;
		a {
			color: #02435d;
			font-weight: 600;
			font-style: italic;
		}
		.contact-mail {
			font-style: italic;
			cursor: pointer;
			//font-family: Assistant;
			font-weight: bold;
			text-decoration: underline;
		}
	}
}

@media only screen and (max-width: 768px) {
	.careers-main-block {
		align-items: center;
		.careers-section-splitter {
			// padding-top: 60px !important;
			width: max-content;
  			margin: auto;
		}
		.careers-title-block {
			font-size: 30px !important;
			padding-bottom: 40px;
		}
		.careers-wrapper-block {
			padding: 0px !important;
			flex-wrap: wrap;
			.job-card {
				height: auto !important;
				width: 100% !important;
				margin-bottom: 20px !important;
				min-height: 0px;
				.responsive-job-selection{
					display: block;
					cursor: pointer;
					width: 8px;
    				height: 15px;
					position: absolute;
					margin-left: 82%;
					margin-top: 11%;
				}
				.job-icon,
				.job-dec-title,
				.job-description {
					display: none;
				}
				.job-content-wrapper {
					.job-title {
						font-size: 19.2px !important;
						text-align: left;
					}
					.split-title{
						width: 90%;
					}
				}

				.job-more-button {
					display: none;
				}
			}
		}
		.apply-job-block {
			// padding: 0px !important;
			line-height: 1.94;
			width: 80%;
			font-size: 16px;
			padding: 0px !important;
			padding-bottom: 40px !important;
		}
	}
}
