.employ-opinion-container {
  margin-bottom: 120px; }
  .employ-opinion-container .opinion-wrapper {
    display: flex;
    height: 100%;
    margin: 28px 80px 28px 140px;
    background-color: #ffffff; }
    .employ-opinion-container .opinion-wrapper .opinion-inner-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 10px;
      box-shadow: 0 5px 9px 5px rgba(204, 204, 204, 0.5);
      height: 380x;
      width: 45%;
      padding: 20px 40px 40px 40px;
      margin-left: 15px;
      margin-right: 15px; }
      .employ-opinion-container .opinion-wrapper .opinion-inner-block .opinion-header-block {
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .employ-opinion-container .opinion-wrapper .opinion-inner-block .opinion-header-block .person-icon {
          width: 130px;
          padding-left: 20px; }
        .employ-opinion-container .opinion-wrapper .opinion-inner-block .opinion-header-block .person-content-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 20px; }
          .employ-opinion-container .opinion-wrapper .opinion-inner-block .opinion-header-block .person-content-wrapper .person-name {
            font-family: Futura;
            font-size: 20px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 25px;
            letter-spacing: normal;
            color: #02435d; }
          .employ-opinion-container .opinion-wrapper .opinion-inner-block .opinion-header-block .person-content-wrapper .person-designation {
            opacity: 0.6;
            font-family: Futura;
            font-size: 15px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 40px;
            letter-spacing: normal;
            color: #02435d; }
      .employ-opinion-container .opinion-wrapper .opinion-inner-block .opinion-description {
        padding: 0 15px;
        text-align: left;
        font-family: Cabin;
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 28px;
        letter-spacing: normal;
        color: #00374c;
        height: 100%; }
  .employ-opinion-container .slide {
    height: 100%;
    background: #ffffff !important; }
  .employ-opinion-container .control-arrow {
    background: #ffffff !important;
    border-top: none !important;
    border-bottom: none !important; }
  .employ-opinion-container .slider-wrapper {
    height: 100% !important; }
    .employ-opinion-container .slider-wrapper .slider {
      height: 80% !important; }

/* Corousel overriden properties */
.control-dots {
  padding-right: 50px;
  position: relative !important; }
  .control-dots .dot {
    outline: none !important; }

.slide {
  background: #f4f4f4 !important; }

.control-prev,
.control-next {
  opacity: 1 !important;
  background: #ffffff !important; }

.control-next::before {
  border-left: 8px solid #ffffff !important; }

.control-prev:before {
  border-right: 8px solid #ffffff !important; }

.control-prev:before,
.control-next:before {
  font-family: Cabin !important;
  font-size: 80px !important;
  line-height: 1 !important;
  color: black !important;
  opacity: 1 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  border-top: none !important;
  border-bottom: none !important; }

.control-prev:before {
  padding-left: 50px !important;
  content: url("../../../assets/images/previous-arrow.svg") !important; }

[dir='rtl'] .control-prev:before {
  content: url("../../../assets/images/previous-arrow.svg") !important; }

[dir='rtl'] .control-next {
  left: -10px !important;
  top: 70px !important;
  right: auto !important; }

.control-next:before {
  padding-right: 50px !important;
  color: #ff5500 !important;
  content: url("../../../assets/images/next-arrow.svg") !important; }

[dir='rtl'] .control-next:before {
  color: #ff5500 !important;
  content: url("../../../assets/images/next-arrow.svg") !important; }
