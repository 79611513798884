@mixin commonInput($height) {
	padding-left: 17px;
	border: solid 1px rgba(2, 67, 93, 0.51);
	height: $height;

	font-family: Cabin;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #02435d;
}

@mixin commonSpace() {
	margin-top: 12px;
}

@mixin commonAlignment() {
	display: flex;
	flex-direction: column;
}

.contact-main-block {
	display: flex;
	flex-direction: column;
	.contact-section-splitter {
		padding-top: 96px;
		width: max-content;
  		margin: auto;
	}
	.contact-title-block {
		padding: 0 0 65px 0;
		font-family: Futura;
		font-size: 35px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 64px;
		letter-spacing: normal;
		text-align: center;
		color: #02435d;
	}
	.contact-overview-container {
		margin-bottom: 30px;
		display: flex;
		width: 100%;
		.contact-address-block {
			width: 50%;
			height: 350px;
			.contact-address {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding-left: 20%;
				.contact-inner-block {
					display: flex;
					align-items: baseline;
					@include commonSpace();
					.phone-icon,
					.mail-icon,
					.linIn-icon,
					.location-icon {
						width: 16px;
						//height: 16px;
					}
					.address-text {
						padding-left: 24px;
						text-align: left;
						font-family: Cabin;
						font-size: 15px;
						font-weight: 400;
						font-style: normal;
						font-stretch: normal;
						line-height: 28px;
						letter-spacing: normal;
						color: #02435d;
					}
				}
				.office-location-image {
					@include commonSpace();
					height: 224px;
					width: 82%;
				}
			}
		}
		.contact-form-block {
			width: 50%;
			padding-right: 81px;
			.contact-us-form {
				display: flex;
				flex-direction: column;
				.validation-block {
					padding-top: 4px;
					text-align: left;
					color: #ec930d;
					font-size: 14px;
				}
				.contact-name {
					margin-bottom: 24px;
					height: 60px;
					@include commonAlignment();
					.input-name {
						@include commonInput(40px);
					}
				}

				.contact-inner-block {
					display: flex;
					width: 100%;
					margin-bottom: 24px;
					.contact-phone {
						height: 60px;
						width: 50%;
						margin-right: 25px;
						@include commonAlignment();
						.input-phone {
							@include commonInput(40px);
							// ::placeholder {
							// 	margin-left: 10px;
							// }

							// :-ms-input-placeholder {
							// 	margin-left: 10px;
							// }
						}
					}
					.contact-email {
						height: 60px;
						@include commonAlignment();
						width: 50%;
						.input-email {
							@include commonInput(40px);
						}
					}
				}
				.input-message {
					margin-bottom: 24px;
					@include commonInput(190px);
				}
				.contact-submit {
					.send-button {
						width: 160px;
						height: 40px;
						font-family: Cabin;
						font-size: 18px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: 40px;
						letter-spacing: normal;
						text-align: center;
						color: #ffffff;
						background-color: #a0a0a0;
					}
				}
			}
		}
	}
}

.modal-main-block {
	display: flex;
	flex-direction: column;
	.contact-section-splitter {
		padding-top: 20px;
		width: max-content;
  		margin: auto;
	}
	.modal-title {
		padding: 30px 0 30px 0;
		font-family: Futura;
		font-size: 35px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 64px;
		letter-spacing: normal;
		text-align: center;
		color: #02435d;
	}
	
	.modal-description {
		padding: 0 15px;
		text-align: left;
		font-family: Cabin;
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
		line-height: 28px;
		letter-spacing: normal;
		color: #00374c;
		height: 100%;
		text-align: center;
	}
	.f-social-media-icon-block {
		display: flex;
		padding-top: 20px;
		justify-content: center;
		.f-fb-icon,
		.f-tr-icon,
		.f-ytb-icon {
			height: 35px;
			padding-right: 20px;
		}
		.f-ytb-icon {
			cursor: pointer;
		}
		.f-tr-icon {
			font-family: Futura;
			font-size: 25px;
			font-weight: 500;
			letter-spacing: normal;
			color: #37b8dc;
		}
	}
}

@media only screen and (max-width: 768px) {
	.contact-main-block {
		.contact-section-splitter {
			// padding-top: 60px !important;
			width: max-content;
  		margin: auto;
		}
		.contact-overview-container {
			flex-direction: column;
			.contact-address-block {
				width: 100% !important;
				height: 250px !important;
				.contact-address {
					padding-left: 10% !important;
					.office-location-image {
						display: none;
					}
				}
			}
			.contact-form-block {
				width: 100% !important;
				padding-left: 0% !important;
				padding-right: 0% !important;
				.contact-us-form {
					padding: 0px 20px 0px 20px !important;
					.contact-inner-block {
						flex-direction: column;
						.contact-phone {
							width: 100% !important;
						}
						.contact-email {
							margin-top: 15px;
							width: 100% !important;
						}
					}
				}
			}
		}
	}
}
