.button-wrapper {
  //box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  //background-color: #ffffff;
  background-color: #ff7d7d;
  cursor: pointer;
  &:hover {
    background-color: #e66d6d;
  }
}
.predefined {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
  height: 33px;
  color: #ffffff;
  font-family: Cabin;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  // font-family: Cabin;
  // font-size: 18px;
  // font-weight: 500;
  // font-style: normal;
  // font-stretch: normal;
  // line-height: normal;
  // letter-spacing: normal;
  // text-align: center;
  // color: black;
}
