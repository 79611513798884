$Iconpath: '../../assets/images/';
.sixsense-carousel-wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	//background-color: #f4f4f4;
	//justify-content: center;
	height: auto;
	//border: 1px solid grey;
	padding-top: 120px;
	.testimonial-header-block {
		display: flex;
		flex-direction: column;
		width: 40%;
		//padding-bottom: 48px;
		.people-say-quotes {
			padding-top: 80px;
			width: max-content;
  			margin: 0px auto;
		}
		.testimonial-header {
			font-family: Futura;
			font-size: 35px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 64px;
			letter-spacing: normal;
			text-align: center;
			color: #02435d;
		}
	}

	.testimonial-carousel-container {
		width: 60%;
		.slider-wrapper {
			height: 100% !important;
		}
		.slider {
			height: auto !important;
		}
		.testimonial-wrapper {
			display: flex;
		}
	}
	.control-dots {
		padding-right: 50px;
		position: relative !important; //Default is absolute
		.dot {
			outline: none !important;
		}
		.selected {
			background: radial-gradient(#1ca7e5, #65d7c8);
		}
	}

	.slide img {
		// margin-top: 35px;
		// width: 48% !important;

		//min-width: 100%;  //*********For making the image width
	}

	// .slide .item-description {
	// }

	.slide {
		background: #ffffff !important;
		.each-image {
			// height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			.image-inner-block {
				margin-top: 50px;
				display: flex;
				//align-items: center;
				height: 100%;
				flex-direction: column;
				// opacity: 0.8;
				//background-color: #e8e8e8;
				border-radius: 10px;
				box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);
				border: solid 1px rgba(2, 67, 93, 0.18);
				width: 60%;
				.item-description {
					text-align: left;
					overflow: auto;
					width: 100% !important;
					padding: 15px 35px 0 35px;
					font-family: Cabin;
					font-size: 15px;
					font-weight: 400;
					font-style: normal;
					font-stretch: normal;
					line-height: 28px;
					letter-spacing: normal;
					color: #00374c;
				}
				.person-name {
					padding-left: 35px;
					text-align: left;
					opacity: 0.5;
					font-family: Futura;
					font-size: 20px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 24px;
					letter-spacing: normal;
					color: #02435d;
				}
				.person-exp-icon {
					padding-left: 35px;
					padding-bottom: 35px;
					// padding-top: 10px;
					//padding-bottom: 10px;
					height: 105px;
					width: 230px;
				}

				.item-description {
					width: 100% !important; //48%
					//height: 55%;
				}
				.person-name {
				}
			}
		}
		.control-arrow {
			background: #f4f4f4 !important;
			border-top: none !important; //For overriding existing courousel properties
			border-bottom: none !important;
		}
	}

	.testimonial-wrapper {
		overflow-x: hidden;
		.opinion-inner-wrapper {
			margin: 20px 0% 20px 0% !important;
			min-width: 100% !important;
			.each-image {
				// height: 100%;
				width: 100%;
				display: flex;
				justify-content: center;
				.image-inner-block {
					margin-top: 50px;
					display: flex;
					//align-items: center;
					height: 100%;
					flex-direction: column;
					// opacity: 0.8;
					//background-color: #e8e8e8;
					border-radius: 10px;
					box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);
					border: solid 1px rgba(2, 67, 93, 0.18);
					margin: 20px 5% 0 5%!important;
					.item-description {
						text-align: left;
						overflow: auto;
						padding: 15px 35px 0 35px;
						font-family: Cabin;
						font-size: 15px;
						font-weight: 400;
						font-style: normal;
						font-stretch: normal;
						line-height: 28px;
						letter-spacing: normal;
						color: #00374c;
					}
					.person-name {
						padding-left: 35px;
						text-align: left;
						opacity: 0.5;
						font-family: Futura;
						font-size: 20px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: 24px;
						letter-spacing: normal;
						color: #02435d;
					}
					.person-exp-icon {
						padding-left: 35px;
						padding-bottom: 35px;
						// padding-top: 10px;
						//padding-bottom: 10px;
						height: 105px;
						width: 230px;
					}
				}
			}
		}
	}

	.control-prev,
	.control-next {
		background: #ffffff !important; //rgba(0, 0, 0, 0.2)
	}

	.control-next::before {
		border-left: 8px solid#ffffff !important;
	}

	.control-prev:before {
		border-right: 8px solid#ffffff !important;
	}

	.control-prev:before,
	.control-next:before {
		color: #ff5500 !important;
		font-family: Cabin !important;
		font-size: 80px !important;
		line-height: 1 !important;
		color: black !important;
		opacity: 1 !important;
		-webkit-font-smoothing: antialiased !important;
		-moz-osx-font-smoothing: grayscale !important;

		border-top: none !important; //For overriding existing courousel properties
		border-bottom: none !important;
	}

	.control-prev:before {
		color: #ff5500 !important;
		// content: '‹' !important;
		content: url($Iconpath+'previous-arrow.svg') !important;
	}
	[dir='rtl'] .control-prev:before {
		color: #ff5500 !important;
		//content: '›' !important;
		content: url($Iconpath+'previous-arrow.svg') !important;
	}

	[dir='rtl'] .control-next {
		color: #ff5500 !important;
		left: -10px !important;
		top: 70px !important;
		right: auto !important;
	}
	.control-next:before {
		color: #ff5500 !important;
		//content: '›' !important;
		content: url($Iconpath+'next-arrow.svg') !important;
	}
	[dir='rtl'] .control-next:before {
		color: #ff5500 !important;
		//content: '‹' !important;
		content: url($Iconpath+'next-arrow.svg') !important;
	}
}

@media only screen and (max-width: 768px) {
	.sixsense-carousel-wrapper {
		margin-bottom: 0px !important;
		flex-direction: column;
		width: 100%;
		padding-top: 0px !important;
		.testimonial-header-block{
			width: 100%;
		}
		.testimonial-carousel-container{
			width: 100%;
			.each-image{
				.image-inner-block{
					width: 100%;
					height: 100%;
					opacity: 1 !important;
					margin: 50px 5% !important;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
					border-radius: initial;
					.person-exp-icon {
						margin-top: 15px;
					}
				}
			}
			.control-arrow{
				display: none !important;
			}
		}
	}	
}
