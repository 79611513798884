.header-wrapper {
	//border-bottom: 1px solid #e6e6e6;
	height: 70px;
	transition: background-color .3s;
	.header-main-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100px;
		.header-icon {
			width: 10%;
			height: 70px;
			.sixsense-icon {
				width: 41px;
				height: 50px;
			}
		}
		.header-primary-content {
			width: 85%;
			height: 100%;
			.primary-content-block {
				display: flex;
				justify-content: flex-end;
				height: 100%;
				.primary-item {
					padding: 0 15px;
					font-family: Futura;
					font-size: 14px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 70px;
					letter-spacing: normal;
					text-align: center;
					color: #d8d8d8;
					border-top: 4px solid transparent;
					cursor: pointer;
					&:hover {
						border-top: 4px solid #65d7c8; //#d0cbcb;
						color: #988c8c;
						// -webkit-transition: color 0.3s ease;
						// -moz-transition: color 0.3s ease;
						// -o-transition: color 0.3s ease;
						// transition: color 0.3s ease;
					}
					// a {
					// 	-webkit-transition: all 0.3s ease;
					// 	-moz-transition: all 0.3s ease;
					// 	-o-transition: all 0.3s ease;
					// 	transition: all 0.3s ease;
					// }
				}
				.active {
					border-top: 4px solid #ffffff;
				}
				.acive-normal {
					border-top: 4px solid #1ca7e5;
				}
			}
		}
		.header-secondary-content {
			width: 15%;
			display: flex;
		}
		.responsive-header-icon {
			display: none;
			.header-nav-icon {
				cursor: pointer;
				width: 40px;
				height: 40px;
			}
		}
	}
}

/* For Handle the scroll functionality */
.enable-normal {
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09) !important;
	-moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09) !important;
	-ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09) !important;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09) !important;
	background-color: #ffff !important;
}

.enable-item {
	color: black !important;
}

/**/

@media only screen and (max-width: 768px) {
	.header-wrapper {
		display: flex;
		height: 100%;
		.header-main-block {
			height: 70px;
			.header-icon {
				width: 10% !important;
				.sixsense-icon {
					height: 70px;
					width: 30px !important;
					padding-left: 10px !important;
				}
			}
		}
		.header-primary-content {
			display: flex !important;
			justify-content: center;
			width: 100% !important;
			top: 95%;
			//left: 35%;
			position: absolute;
			//z-index: -1;
			.primary-content-block {
				height: 100vh !important;
				justify-content: flex-start !important;
				background-color: #ffffff;
				flex-direction: column;
				width: 100%;
				-webkit-transition: height 0.3s ease;
				-moz-transition: height 0.3s ease;
				-o-transition: height 0.3s ease;
				transition: height 0.5s ease;
				.primary-item {
					line-height: 60px !important;
					color: #000000 !important;
				}
			}
		}
		.header-secondary-content {
			width: 70% !important;
		}
		.responsive-header-icon {
			display: block !important;
			margin-right: 15px;
		}
	}
}
