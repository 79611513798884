@mixin commonTitle() {
  font-family: Futura;
  font-size: 35px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #02435d;
}

@mixin commonContent($textAlign) {
  font-family: Cabin;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #02435d;
}

.about-work-wrapper {
  padding-top: 115px;
  .about-work-container {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    .about-work-title {
      margin-top: 7px;
      @include commonTitle();
    }
    .about-work-main-block {
      margin-top: 86px;
      .about-work-item-wrpper {
        // margin-top: 184px;
        .about-work-item {
          display: flex;
          width: 100%;
          //margin: 20px;
          .about-work-left {
            margin-top: -35px;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .work-item-title {
              text-align: left;
              padding: 0 0 16px 0;
              font-family: Futura;
              font-size: 30px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: #02435d;
            }
            .work-item-subtitle {
              text-align: left;
              font-family: Cabin;
              font-size: 24px;
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              line-height: 1;
              letter-spacing: normal;
              color: #02435d;
            }
            .work-item-content {
              @include commonContent(left);
              color: #00374c !important;
              padding: 16px 0 24px 0;
              width: 100%;
            }
            .demo-button {
              width: 170px;
              height: 50px;
              color: #ffffff;
              font-family: Cabin;
              font-size: 15px;
              font-weight: 500;
              line-height: 46px;
              text-align: center;
              letter-spacing: normal;
            }
          }
          .about-us-splitter {
            // width: 1%;
            height: 100%;
          }
          .about-work-right {
            width: 50%;
            .about-work-icon {
              width: 100%;
              //   height: 298px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .about-work-wrapper {
    // padding-top: 10px !important;
    .section-splitter-2 {
      width: max-content;
      margin: auto;
    }
    .about-work-container {
      //padding: 0 24px 0 24px;
      .about-work-title {
        margin-top: 7px !important;
        font-size: 30px !important;
      }
      .about-work-main-block {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: hidden;
        //padding: 0 24px 0 24px;
        //padding: 0 13px 0 13px;
        //-webkit-overflow-scrolling: touch;
        //justify-content: space-between;
        background: none !important;
        margin-top: 30px !important;
        z-index: 2;
        //width: 100%;
        .about-work-item-wrpper {
          margin: 20px 0% 20px 0% !important;
          min-width: 100% !important;
          .about-work-item {
            width: 90%;
            margin: 0 5%;
            max-width: 100%;
            border-radius: 5px;
            //flex: 0 0 auto;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            flex-direction: column-reverse !important;

            .about-work-right {
              padding: 0 !important;
              width: 100% !important;
              padding: 0px !important;
              margin-top: 20px !important;
              .about-work-icon {
                height: 186px;
                object-fit: contain;
              }
            }
            .about-work-left {
              padding: 0 !important;
              width: 89% !important;
              padding: 0px !important;
              padding-left: 20px !important;
              padding-right: 20px !important;
              margin-bottom: 20px !important;
            }
            .work-item-title {
              line-height: 2.13;
              font-size: 26px !important;
              padding-bottom: 6px !important;
            }
            .work-item-subtitle {
              font-size: 16px !important;
            }
            .work-item-content {
              line-height: 1.4 !important;
            }
            .about-work-item-bottom {
              position: absolute;
              width: 100%;
              height: 190px;
              right: 0;
              bottom: 0;
              background: '#fff';
            }
          }
        }
      }
      .control-circle-dots {
        display: flex;
        justify-content: center;
        padding-left: 0px;
        .circle-dot {
          transition: opacity 0.25s ease-in;
          opacity: 0.3;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
          background: #fff;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          cursor: pointer;
          display: inline-block;
          margin: 0 8px;
        }
        .seleted {
          opacity: 1 !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .about-work-container {
    max-width: 100vw;
  }
}

@media (min-width: 769px) {
  .about-work-container {
    max-width: 650px;
  }
  .about-work-item-bottom {
    height: 100px !important;
  }
}

@media (min-width: 992px) {
  .about-work-container {
    max-width: 880px;
  }
  .about-work-item-bottom {
    height: 160px !important;
  }
}

@media (min-width: 1200px) {
  .about-work-container {
    max-width: 1020px;
  }
  .about-work-item-bottom {
    height: 160px !important;
  }
}