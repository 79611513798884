.products-main-block {
	height: 100%;
	align-items: center;
	display: flex;
	flex-direction: column;
	//background-color: #d8d8d8;
	.product-section-splitter {
		padding-top: 180px;
		z-index: 1;
		width: max-content;
  		margin: auto;
	}
	.products-title-block {
		padding: 0 0 60px 0;
		font-family: Futura;
		font-size: 35px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 64px;
		letter-spacing: normal;
		color: #02435d;
	}
	.products-overview-container {
		display: flex;
		padding: 20px 0 45px 20px;
		.each-product-wrpper{
			.each-product {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0 30px 0 30px;
				.product-icon {
					width: 92px;
					height: 90px;
					padding-bottom: 5px;
				}
				.product-item-wrapper{
					display: flex;
					flex-direction: column;
					.product-item-name {
						font-family: Futura;
						font-size: 20px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: 64px;
						letter-spacing: normal;
						text-align: center;
						color: #02435d;
					}
					.product-item-description {
						width: 186px;
						font-family: Cabin;
						font-size: 15px;
						font-weight: 400;
						font-style: normal;
						font-stretch: normal;
						line-height: 28px;
						letter-spacing: normal;
						text-align: center;
						color: #00374c;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.products-main-block {
		.product-section-splitter {
			padding-top: 30px !important;
			width: max-content;
  			margin: auto;
		}
		.products-title-block {
			font-size: 25px !important;
			padding: 0px !important;
		}
		.products-overview-container {
			width: 100%;
			flex-direction: row !important;
			padding: 0 !important;
			flex-wrap: nowrap;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
			.each-product-wrpper{
				margin: 20px 0% 20px 0% !important;
				min-width: 100% !important;
				.each-product{
					border-radius: 5px;
					padding: 0 !important;
					flex: 0 0 auto;
					flex-direction: row !important;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);					
					width: 90% !important;
    			margin: 0 5% !important;
					.product-icon{
						padding-right: 10px;
						padding-left: 20px !important;
					}
					.product-item-wrapper{
						padding: 10px 10px 10px 0px !important;
						.product-item-name{
							font-size: 16px !important;
							text-align: left !important;
						}
						.product-item-description{
							width: 100%;
							padding-bottom: 20px;
							font-size: 15px !important;
							//overflow-y: auto;
							text-align: left !important;
						}
					}
				}
			}			
		}
		.control-circle-dots{
			display: flex;
			justify-content: center;
			padding-left: 0px;
			.circle-dot{
				transition: opacity .25s ease-in;
				opacity: .3;
				box-shadow: 1px 1px 2px rgba(0,0,0,.9);
				background: #fff;
				border-radius: 50%;
				width: 8px;
				height: 8px;
				cursor: pointer;
				display: inline-block;
				margin: 0 8px;
			}
			.seleted{
				opacity: 1 !important;
			}
		}
	}
}
