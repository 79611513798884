.layout-main-container {
	display: flex;
	flex-direction: column;
	.header-section {
		z-index: 3;
		position: fixed;
		width: 100%;
		height: 70px;
		box-shadow: 0 6px 12px 0 rgba(0, 55, 76, 0.1);
		//background: #093448;
		//z-index: 99999;
	}
	.main-content-section {
		//margin-top: 100px;
		.each-section {
			height: 538px;
			width: 100%;
			//border: 1px solid #e6e6e6;
			//background: #dee2d5;
		}
	}
}

@media only screen and (max-width: 768px) {
	.layout-main-container {
		.header-section {
			//height: 102px;
			background: transparent !important;
		}
	}
}
