$Iconpath: '../../../assets/images/';

.employ-opinion-container {
	margin-bottom: 120px;
	.employ-carousel-container {
	}
	.opinion-wrapper {
		//width: 100%;
		display: flex;
		height: 100%;
		margin: 28px 80px 28px 140px;

		background-color: #ffffff;
		.opinion-inner-block {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border-radius: 10px;
			box-shadow: 0 5px 9px 5px rgba(204, 204, 204, 0.5);
			height: 380x;
			width: 45%;
			padding: 20px 40px 40px 40px;
			margin-left: 15px;
			margin-right: 15px;
			.opinion-header-block {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				.person-icon {
					//width: 120px;
					// height: 120px;
					width: 130px;
					padding-left: 20px;
					//border-radius: 50%;
				}
				.person-content-wrapper {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding-left: 20px;
					.person-name {
						font-family: Futura;
						font-size: 20px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: 25px;
						letter-spacing: normal;
						color: #02435d;
					}
					.person-designation {
						opacity: 0.6;
						font-family: Futura;
						font-size: 15px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: 40px;
						letter-spacing: normal;
						color: #02435d;
					}
				}
			}
			.opinion-description {
				padding: 0 15px;
				text-align: left;
				font-family: Cabin;
				font-size: 15px;
				font-weight: 400;
				font-style: normal;
				font-stretch: normal;
				line-height: 28px;
				letter-spacing: normal;
				color: #00374c;
				height: 100%;
			}
		}
	}
	.slide {
		// margin-top: 35px;
		// width: 48% !important;
		//pointer-events: none;
		height: 100%;
		background: #ffffff !important;
		//min-width: 50% !important; //*********For making the image width
	}
	.control-arrow {
		background: #ffffff !important;
		border-top: none !important; //For overriding existing courousel properties
		border-bottom: none !important;
	}
	.slider-wrapper {
		height: 100% !important;
		.slider {
			height: 80% !important;
		}
	}
}

/* Corousel overriden properties */
.control-dots {
	padding-right: 50px;
	position: relative !important; //Default is absolute
	.dot {
		outline: none !important;
	}
}

.slide {
	background: #f4f4f4 !important;
}

.control-prev,
.control-next {
	opacity: 1 !important;
	background: #ffffff !important; //rgba(0, 0, 0, 0.2);
	//pointer-events: none;
}

.control-next::before {
	border-left: 8px solid#ffffff !important;
}

.control-prev:before {
	border-right: 8px solid#ffffff !important;
}

.control-prev:before,
.control-next:before {
	font-family: Cabin !important;
	font-size: 80px !important;
	line-height: 1 !important;
	color: black !important;
	opacity: 1 !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;

	border-top: none !important; //For overriding existing courousel properties
	border-bottom: none !important;
}

.control-prev:before {
	//content: '‹' !important;
	padding-left: 50px !important;
	content: url($Iconpath+'previous-arrow.svg') !important;
}
[dir='rtl'] .control-prev:before {
	//content: '-›' !important;
	content: url($Iconpath+'previous-arrow.svg') !important;
}

[dir='rtl'] .control-next {
	left: -10px !important;
	top: 70px !important;
	right: auto !important;
}
.control-next:before {
	padding-right: 50px !important;
	color: #ff5500 !important;
	//content: '-›' !important;
	content: url($Iconpath+'next-arrow.svg') !important;
}
[dir='rtl'] .control-next:before {
	color: #ff5500 !important;
	//content: '‹' !important;
	content: url($Iconpath+'next-arrow.svg') !important;
}
