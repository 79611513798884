.home-main-block {
  display: flex;
  height: 100%;
  justify-content: center;
  // background-image: url('/assets/images/home-background.svg');
  // background-size: '80px 80px';
  // background-repeat: no-repeat, repeat;
  // .home-background-wrapper {
  // 	position: absolute;
  // 	height: 400px;
  // 	width: 100%;
  // 	// width: 120px;
  // 	// border-bottom: 120px solid #ec3504;
  // 	// border-left: 60px solid transparent;
  // 	// border-right: 60px solid transparent;
  // 	background-color: #f3f3f3;
  // }
  .actual-img,
  .preload-img {
    width: 100%;
    z-index: -1;
    margin: unset !important;
  }
  .home-content-block {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;

    position: absolute;

    .home-sixsense-icon {
      padding-top: 110px;
      height: 94px;
      object-fit: contain;
    }
    .home-title {
      font-family: Futura;
      font-size: 50px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 64px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
    .home-sub-title {
      font-family: Cabin;
      font-size: 22px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 28px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
    .home-button {
      display: flex;
      justify-content: center;
      padding-top: 21px;
      .home-know-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 50px;
        color: #ffffff;
        font-family: Cabin;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .home-down-arrow {
      width: max-content;
  		margin: auto;
      margin-top: 80px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-main-block {
    .actual-img {
      //height: 70% !important;
      //margin-bottom: 30% !important;
    }
    .preload-img {
      // height: 70% !important;
      margin-bottom: 30% !important;
    }
    .home-content-block {
      .home-sixsense-icon {
        padding-top: 120px !important;
        height: 80px !important;
      }
      .home-title {
        margin-top: 8px;
        font-size: 35px !important;
      }
      .home-sub-title {
        margin-top: 12px;
        font-size: 16px !important;
        padding: 0 50px !important;
      }
      .home-button {
        padding-top: 30px !important;
        .home-know-button {
          width: 115px !important;
          height: 35px !important;
        }
      }
      .home-down-arrow {
        width: max-content;
  		  margin: auto;
        margin-top: 80px !important;
      }
    }
  }
}
